import { codeableConceptCategories } from '@/constants';
import { PaginatedResponse, PaginatedResponseMeta, Status } from '@/models';
import { EpisodeOfCareResponseData } from '@/models/episode-of-care/episode-of-care.model';
import OphthalmologyConsultViewModel from '@/models/ophthalmology-consult.view.model';
import {
  CodeableConceptService,
  EncounterService,
  EpisodeOfCareService
} from '../api';

import { CodeableConcept } from '@/lib';
import { CancelToken } from 'axios';

export interface PaginationOptions {
  page: number;
  sort: string;
  perPage: number;
}

export interface RequestParams {
  [key: string]: unknown;
}

export type EpisodeOfCareCountKeys = Status | 'All';

export type EpisodeOfCareStatusCounts = {
  [key in EpisodeOfCareCountKeys]: number;
}

export interface EpisodeOfCareResponseMeta extends PaginatedResponseMeta {
  status_counts: EpisodeOfCareStatusCounts;
}

export default class OphthalmologyConsultAggregateService {
  private _episodeOfCareService: EpisodeOfCareService;
  private _codeableConceptService: CodeableConceptService;
  private _encounterService: EncounterService;
  private _statuses: CodeableConcept[] = [];
  private _participantTypeCodes: CodeableConcept[] = [];
  private _encounterClasses: CodeableConcept[] = [];

  constructor(
    episodeOfCareService: EpisodeOfCareService,
    codeableConceptService: CodeableConceptService,
    encounterService: EncounterService
  ) {
    this._episodeOfCareService = episodeOfCareService;
    this._codeableConceptService = codeableConceptService;
    this._encounterService = encounterService;
  }

  public get episodeOfCareService(): EpisodeOfCareService {
    return this._episodeOfCareService;
  }

  public get codeableConceptService(): CodeableConceptService {
    return this._codeableConceptService;
  }

  public get encounterService(): EncounterService {
    return this._encounterService;
  }

  public async fetchAllByPatientId(
    organisationId: string,
    patientId: string,
    paginationOptions?: PaginationOptions
  ): Promise<PaginatedResponse<OphthalmologyConsultViewModel[]>> {
    return await this.fetchAllByParams(
      this.getFetchAllParams(organisationId, {
        patientId,
        paginationOptions
      }),
      organisationId
    );
  }

  private getFetchAllParams(
    organisationId: string,
    options?: {
      patientId?: string;
      paginationOptions?: PaginationOptions;
      extraParams?: RequestParams;
    }
  ): RequestParams {
    let params: RequestParams =
      options && options.extraParams
        ? options.extraParams
        : {
          include: 'ophthalmologyEpisodeOfCareDetail,media,patient',
          'filter[ownerOrProvider]': organisationId
        };

    if (options?.patientId) {
      params['filter[patient]'] = options?.patientId;
    }

    if (options?.paginationOptions) {
      params = {
        ...options?.paginationOptions,
        ...params
      };
    }
    return {
      statusCounts: true,
      ...params
    };
  }

  private async fetchAllByParams(
    params: RequestParams,
    organisationId: string
  ): Promise<PaginatedResponse<OphthalmologyConsultViewModel[], EpisodeOfCareResponseMeta>> {
    const episodesOfCareResponse = (await this.episodeOfCareService.index({
      params
    })) as PaginatedResponse<EpisodeOfCareResponseData[], EpisodeOfCareResponseMeta>;
    await this.syncAllOphthalmologyCodeableConcepts();

    return {
      data: episodesOfCareResponse.data.map(
        (episodeOfCare: EpisodeOfCareResponseData) =>
          new OphthalmologyConsultViewModel(
            episodeOfCare,
            this._statuses,
            this._participantTypeCodes,
            this._encounterClasses,
            organisationId
          )
      ),
      links: episodesOfCareResponse.links,
      meta: episodesOfCareResponse.meta
    };
  }

  public async syncAllOphthalmologyCodeableConcepts(): Promise<void> {
    if (
      this._statuses.length > 0 &&
      this._participantTypeCodes.length > 0 &&
      this._encounterClasses.length > 0
    ) {
      return;
    }

    const allCodeableConcepts = await this.fetchCodeableConceptsByParams({
      'filter[category][0]': codeableConceptCategories.encounterStatus,
      'filter[category][1]': codeableConceptCategories.participantType,
      'filter[category][2]': codeableConceptCategories.encounterClass
    });

    this._statuses = allCodeableConcepts.filter((codeableConcept: CodeableConcept) => (
      codeableConcept.category === codeableConceptCategories.encounterStatus
    ));
    this._participantTypeCodes = allCodeableConcepts.filter((codeableConcept: CodeableConcept) => (
      codeableConcept.category === codeableConceptCategories.participantType
    ));
    this._encounterClasses = allCodeableConcepts.filter((codeableConcept: CodeableConcept) => (
      codeableConcept.category === codeableConceptCategories.encounterClass
    ));
  }

  public async fetchCodeableConceptsByCategory(
    category: string
  ): Promise<CodeableConcept[]> {
    return await this.fetchCodeableConceptsByParams({
      'filter[category]': category
    });
  }

  public async fetchCodeableConceptsByParams(
    params: any
  ): Promise<CodeableConcept[]> {
    return (
      await this.codeableConceptService.index({
        params
      })
    ).data;
  }

  public async fetchAll(
    organisationId: string,
    options: {
      patientId?: string;
      paginationOptions?: PaginationOptions;
      extraParams?: RequestParams;
      cancelToken?: CancelToken;
    }
  ): Promise<PaginatedResponse<OphthalmologyConsultViewModel[], EpisodeOfCareResponseMeta>> {
    return await this.fetchAllByParams(
      this.getFetchAllParams(organisationId, options),
      organisationId
    );
  }
}
