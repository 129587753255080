import { CodeableConcept } from '@/lib';
import {
  EncounterParticipant,
  EncounterParticipantUser,
  EncounterStatus,
  EpisodeOfCareEncounter,
  EpisodeOfCareResponseData,
  OphthalmologyDetails,
  OphthalmologyDetailsConsultType,
  OphthalmologyDetailsLocation
} from '@/models/episode-of-care/episode-of-care.model';
import { Clinic } from './consult.model';
import { Status } from './patient-consult.model';
import { Patient } from './patient.model';
import { TriageStatus } from './triage.model';

class OphthalmologyConsultViewModel {
  private _episodeOfCare: EpisodeOfCareResponseData;
  private _statuses: CodeableConcept[];
  private _participantTypeCodes: CodeableConcept[];
  private _encounterClasses: CodeableConcept[];
  private _organisationId: string;
  private _createdParticipantCode: CodeableConcept;
  private _reviewParticipantCode: CodeableConcept;
  private _virtualEncounterCode: CodeableConcept;
  private _ambulatoryEncounterCode: CodeableConcept;

  constructor(
    episodeOfCare: EpisodeOfCareResponseData,
    statuses: CodeableConcept[],
    participantTypeCodes: CodeableConcept[],
    encounterClasses: CodeableConcept[],
    organisationId: string

  ) {
    this._episodeOfCare = episodeOfCare;
    this._statuses = statuses;
    this._participantTypeCodes = participantTypeCodes;
    this._encounterClasses = encounterClasses;
    this._organisationId = organisationId;
    this._createdParticipantCode = this.findParticipantTypeByCode('CRE');
    this._reviewParticipantCode = this.findParticipantTypeByCode('REV');
    this._virtualEncounterCode = this.findEncounterByCode('VR');
    this._ambulatoryEncounterCode = this.findEncounterByCode('AMB');
  }

  private findEncounterByCode(code: string): CodeableConcept {
    const maybeEncounter: CodeableConcept | undefined =
      this.encounterClasses.find(
        (codableConcept) => codableConcept.code === code
      );
    if (!maybeEncounter) {
      throw new Error('Encounter not found');
    }
    return maybeEncounter;
  }

  private findParticipantTypeByCode(code: string): CodeableConcept {
    const maybeParticipantType: CodeableConcept | undefined =
      this.participantTypeCodes.find(
        (codableConcept) => codableConcept.code === code
      );
    if (!maybeParticipantType) {
      throw new Error('Participant type not found');
    }
    return maybeParticipantType;
  }

  public get episodeOfCare(): EpisodeOfCareResponseData {
    return this._episodeOfCare;
  }

  public set episodeOfCare(value: EpisodeOfCareResponseData) {
    this._episodeOfCare = value;
  }

  public get encountersList(): EpisodeOfCareEncounter[] {
    return this._episodeOfCare.encounters;
  }

  public get statuses(): CodeableConcept[] {
    return this._statuses;
  }

  public set statuses(value: CodeableConcept[]) {
    this._statuses = value;
  }

  public get participantTypeCodes(): CodeableConcept[] {
    return this._participantTypeCodes;
  }

  public set participantTypeCodes(value: CodeableConcept[]) {
    this._participantTypeCodes = value;
  }

  public get encounterClasses(): CodeableConcept[] {
    return this._encounterClasses;
  }

  public set encounterClasses(value: CodeableConcept[]) {
    this._encounterClasses = value;
  }

  public get organisationId(): string {
    return this._organisationId;
  }

  public set organisationId(value: string) {
    this._organisationId = value;
  }

  public get reviewParticipantCode(): CodeableConcept {
    return this._reviewParticipantCode;
  }

  public get createdParticipantCode(): CodeableConcept {
    return this._createdParticipantCode;
  }

  public get virtualEncounterCode(): CodeableConcept {
    return this._virtualEncounterCode;
  }

  public get ambulatoryEncounterCode(): CodeableConcept {
    return this._ambulatoryEncounterCode;
  }

  public get ophthalmologyDetails(): OphthalmologyDetails {
    return this.episodeOfCare.ophthalmology_details;
  }

  public get isLocked(): boolean {
    return !!this.ophthalmologyDetails.locked_by;
  }

  public get clinic(): Clinic {
    return this.episodeOfCare.ophthalmology_details.clinic;
  }

  public get clinicName(): string {
    return this.episodeOfCare.ophthalmology_details.clinic_configuration_name;
  }

  public get createdDate(): string {
    return this.episodeOfCare.created_at;
  }

  public get reviewIsOverdue(): boolean {
    return Boolean(this.episodeOfCare.review_overdue);
  }

  public get hasConsultDate(): boolean {
    return this.ambulatoryEncounter && this.ambulatoryEncounterHasFinished;
  }

  public get consultDate(): string {
    if (!this.ambulatoryEncounterHasFinished) {
      throw new Error('Consult Date not found');
    }
    return this.ambulatoryEncounterFinishedDate;
  }

  public get hasScheduledDate(): boolean {
    return Boolean(
      this.ambulatoryEncounter?.ophthalmology_schedule_details?.scheduled_at
    );
  }

  public get scheduledDate(): string {
    if (
      !this.ambulatoryEncounter?.ophthalmology_schedule_details?.scheduled_at
    ) {
      throw new Error('Schedule Date not found');
    }
    return this.ambulatoryEncounter.ophthalmology_schedule_details.scheduled_at;
  }

  public get hasPatient(): boolean {
    return Boolean(this.episodeOfCare.patient);
  }

  public get patient(): Patient {
    if (!this.episodeOfCare.patient) {
      throw new Error('Patient not found');
    }
    return this.episodeOfCare.patient;
  }

  public get patientIsDeceased(): boolean {
    return Boolean(this.patient.deceased_at);
  }

  public get patientMrn(): string {
    if (this.isOwnerOrganisation) {
      return this.episodeOfCare.patient_mrn_at_clinic_owner;
    }
    return this.episodeOfCare.patient_mrn_at_clinic_provider;
  }

  public get isOwnerOrganisation(): boolean {
    return this.episodeOfCare.ophthalmology_details.clinic.owner.id === this.organisationId;
  }

  public get isProviderOrganisation(): boolean {
    return this.episodeOfCare.ophthalmology_details.clinic.provider.id === this.organisationId;
  }

  public get hasLocation(): boolean {
    return Boolean(this.episodeOfCare.ophthalmology_details.location);
  }

  public get location(): OphthalmologyDetailsLocation {
    if (!this.episodeOfCare.ophthalmology_details.location) {
      throw new Error('Location not found');
    }
    return this.episodeOfCare.ophthalmology_details.location;
  }

  public get status(): Status {
    return this.episodeOfCare.status;
  }

  public get consultType(): OphthalmologyDetailsConsultType {
    return this.episodeOfCare.ophthalmology_details.consult_type;
  }

  public get hasTriageStatus(): boolean {
    return Boolean(this.episodeOfCare.ophthalmology_details.triage_status);
  }

  public get triageStatus(): TriageStatus {
    if (!this.episodeOfCare.ophthalmology_details.triage_status) {
      throw new Error('Triage status not found');
    }
    return this.episodeOfCare.ophthalmology_details.triage_status;
  }

  public get participants(): EncounterParticipant[] {
    return this.encountersList.flatMap((e: EpisodeOfCareEncounter) => e.participants);
  }

  public get reviewParticipants(): EncounterParticipant[] {
    return this.participants.filter(
      (participant: EncounterParticipant) =>
        participant.type_id === this.reviewParticipantCode.id
    );
  }

  public get hasReviewer(): boolean {
    return Boolean(this.reviewParticipants.length > 0);
  }

  public get reviewer(): EncounterParticipantUser {
    const maybeParticipant: EncounterParticipant | undefined = this.reviewParticipants
      .sort((a, b) => (new Date(a.end)).getTime() - (new Date(b.end)).getTime())[0];

    if (!maybeParticipant) {
      throw new Error('Reviewer not found');
    }
    return maybeParticipant.user;
  }

  public get virtualEncounter(): EpisodeOfCareEncounter | null {
    const encounters: EpisodeOfCareEncounter[] = this.episodeOfCare.encounters;

    const maybeEncounter = encounters.find(
      (encounter) => encounter.class === this.virtualEncounterCode?.id
    );

    return maybeEncounter ? maybeEncounter : null;
  }

  public get ambulatoryEncounter(): EpisodeOfCareEncounter | null {
    const encounters: EpisodeOfCareEncounter[] = this.episodeOfCare.encounters;

    const maybeEncounter = encounters.find(
      (encounter) => encounter.class === this.ambulatoryEncounterCode?.id
    );

    return maybeEncounter ? maybeEncounter : null;
  }

  public get ambulatoryEncounterIsRejected(): boolean {
    return !!(
      this.ambulatoryEncounter && this.ambulatoryEncounterRejectedStatus
    );
  }

  public get ambulatoryEncounterRejectedStatus(): EncounterStatus | null {
    if (!this.ambulatoryEncounter) {
      throw new Error('No ambulatory encounter');
    }
    const maybeRejectedCode: CodeableConcept | undefined = this.statuses.find(
      (codableConcept) => codableConcept.code === 'cancelled'
    );
    if (!maybeRejectedCode) {
      throw new Error('Cancelled code not found');
    }
    const maybeRejectedStatus: EncounterStatus | undefined =
      this.ambulatoryEncounter.statuses.find(
        (status: EncounterStatus) => status.status_id === maybeRejectedCode.id
      );
    return maybeRejectedStatus || null;
  }

  public get ambulatoryEncounterRejectedDate(): string {
    if (!this.ambulatoryEncounterRejectedStatus) {
      throw new Error('No rejected status');
    }
    return this.ambulatoryEncounterRejectedStatus.start;
  }

  public get ambulatoryEncounterHasFinished(): boolean {
    return Boolean(this.ambulatoryEncounterFinishedStatus);
  }

  public get ambulatoryEncounterFinishedStatus(): EncounterStatus | null {
    if (!this.ambulatoryEncounter) {
      throw new Error('No ambulatory encounter');
    }
    const maybeFinishedCode: CodeableConcept | undefined = this.statuses.find(
      (codableConcept) => codableConcept.code === 'finished'
    );
    if (!maybeFinishedCode) {
      throw new Error('Finished code not found');
    }
    const maybeFinishedStatus: EncounterStatus | undefined =
      this.ambulatoryEncounter.statuses.find(
        (status: EncounterStatus) => status.status_id === maybeFinishedCode.id
      );
    return maybeFinishedStatus || null;
  }

  public get ambulatoryEncounterFinishedDate(): string {
    if (!this.ambulatoryEncounterFinishedStatus) {
      throw new Error('No finished status');
    }
    return this.ambulatoryEncounterFinishedStatus.start;
  }

  public get ambulatoryEncounterFormSubmissionId(): string {
    if (!this.ambulatoryEncounter) {
      throw new Error('No ambulatory encounter');
    }
    return this.ambulatoryEncounter.form_submission_id;
  }

  public get hasRejectedReasonType(): boolean {
    return Boolean(this.ambulatoryEncounter?.extension?.rejected);
  }

  public get rejectedReasonType(): string {
    if (!this.ambulatoryEncounter?.extension?.rejected.type) {
      throw new Error('Rejected Reason not found');
    }
    return this.ambulatoryEncounter.extension.rejected.type;
  }

  public get hasFileAttachmentId(): boolean {
    return Boolean(this.episodeOfCare.file_attachment_id);
  }

  public get fileAttachmentId(): string {
    if (!this.episodeOfCare.file_attachment_id) {
      throw new Error('File attachment id not found');
    }
    return this.episodeOfCare.file_attachment_id;
  }
}

export default OphthalmologyConsultViewModel;
